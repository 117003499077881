<template>
  <div>
    <b-card>

      <!-- form -->
      <b-form
        class="mt-2"
        @submit.stop.prevent="onSubmit"
      >
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <h3 class="mb-0 font-weight-normal">
                Create User
              </h3>
            </div>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="UserName"
              label-for="user-name"
              class="mb-2"
            >
              <b-form-input
                id="user-name"
                v-model="userData.username"
                :state="validateState('username')"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="First Name"
              label-for="user-first_name"
              class="mb-2"
            >
              <b-form-input
                id="user-first_name"
                v-model="userData.first_name"
                :state="validateState('first_name')"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Last Name"
              label-for="user-last_name"
              class="mb-2"
            >
              <b-form-input
                id="user-last_name"
                v-model="userData.last_name"
                :state="validateState('last_name')"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="user-email"
              class="mb-2"
            >
              <b-form-input
                id="user-email"
                v-model="userData.email"
                :state="validateState('email')"
              />
              <span
                v-if="!emailIsUnique"
                class="danger"
              >Email must be unique.
              </span>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Password"
              label-for="user-password"
              class="mb-2"
            >
              <b-form-input
                id="user-password"
                v-model="userData.password"
                type="password"
                :state="validateState('password')"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Role"
              label-for="user-role"
              class="mb-2"
            >
              <b-form-select
                id="user-role"
                v-model="userData.role"
                :options="roleList"
                :placeholder="'Select Role'"
                :state="validateState('role')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Create User
            </b-button>
            <b-button
              variant="outline-secondary"
              :to="{ name: 'admin-users' }"
            >
              Discard
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import Editor from '@tinymce/tinymce-vue'
import Ripple from 'vue-ripple-directive'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    editor: Editor,
  },
  directives: {
    Ripple,
  },
  mixins: [
    validationMixin,
  ],
  data() {
    return {
      users: null,
      emailIsUnique: true,
      userData: {
        username: null,
        email: null,
        role: null,
        first_name: null,
        last_name: null,
        password: null,
      },
      roleList: [],
      tinyAPI: process.env.VUE_APP_TINYMCE,
    }
  },
  watch: {
    'userData.email': function (newVal) {
      if (!newVal) return
      if (this.users.find(user => user.email === this.userData.email)) {
        // console.log('isExist')
        this.emailIsUnique = false
      } else {
        this.emailIsUnique = true
      }
    },
  },
  mounted() {
    this.getRoles()
  },
  async created() {
    await this.getUsers()
  },
  validations: {
    userData: {
      email: {
        required,
        email,
      },
      username: {
        required,
        minLength: minLength(1),
      },
      first_name: {
        required,
        minLength: minLength(1),
      },
      last_name: {
        required,
        minLength: minLength(1),
      },
      password: {
        required,
        minLength: minLength(8),
      },
      role: {
        required,
      },
    },
  },
  methods: {
    async getUsers() {
      axios
        .get('/auth/users')
        .then(response => {
          this.users = response.data.data
        })
    },
    getRoles() {
      axios
        .get('/auth/roles')
        .then(response => {
          this.roleList = response.data.data.map(d => (
            { value: d.id, text: d.name }
          ))
        })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.userData[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // Email must be unique
      if (this.users.find(user => user.email === this.userData.email)) {
        this.emailIsUnique = false
        return
      }
      this.$v.userData.$touch()
      if (this.$v.userData.$anyError) {
        // console.log(this.$v.userData)
        return
      }
      const data = {
        username: this.userData.username,
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        email: this.userData.email,
        password: this.userData.password,
        role_id: this.userData.role,
      }
      axios
        .post('/auth/users', data)
        .then(() => this.$router.push({ name: 'admin-users' }))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.layout-img {
  max-width: 100%;
  opacity: 0.5;
}
.isActive {
  opacity: 1;
}
.custom-file-label::after {
  background-color: #7367F0;
  color: #fff;
}
.embed-responsive {
  width: 170px!important;
  height: 106px!important;
  margin-right: 1rem;
}
.is-invalid {
  .tox-tinymce {
    border-color: #ea5455!important;
  }
}
.danger{
  position: absolute;
  font-size: 12px;
  color: #ea5455;
}
</style>
